import OdienceLogoDefault from "@/assets/Odience-logo_nav-white.svg";
import BackIcon from "@/assets/back.svg";
import StaffIcon from "@/assets/icon_staff.svg";
import PrivateIcon from "@/assets/private.svg";
import QrSmall from "@/assets/qr-red.svg";
import { paths } from "@/routerPaths";
import { colors } from "@/styles/global.styles";
import { generateRandomString, isMobile } from "@/utils/helpers/Utils";
import { isProvisioned } from "@/utils/helpers/provisionRequest";
import { useToast } from "@/utils/helpers/toastManager";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import io from "socket.io-client";
import { routerUrl } from "../../../../utils";
import {
  getLocalDirectorToken,
  getLocalUser,
} from "../../../../utils/helpers/localstorage";
import {
  authenticateDirector,
  fetchEventInfo,
  fetchPublicEventInfo,
} from "../../../../utils/hooks/useDirectorAuthentication";
import GetAppModal from "../../components/GetAppModal";

import ConfirmationPopup from "@/components/shared/ConfirmationPopup";
import { categories } from "@/utils/constants/odience";
import { atoms } from "@/utils/helpers/atoms";
import {
  getEmail,
  getInvite,
  removeEmail,
  removeInvite,
  removeInvitedEventId,
  removeLastEventId,
  removeLastGroupId,
  setEmail,
  setInvite,
  setLastEventId,
  setLastGroupId,
} from "@/utils/helpers/sessionStorage";

import useParentIframeUrl from "@/utils/hooks/useUpdateIframeParent";
import { isSocketIOConnected } from "@/utils/network";
import { isEmptyObject } from "@tiptap/react";
import { useNetworkState } from "@uidotdev/usehooks";
import { useSetAtom } from "jotai";
import { useUnmount } from "usehooks-ts";
import { generateEventListPath } from "../../../../utils/hooks/useOdienceEvents";
import { useOdienceOrganization } from "../../../../utils/hooks/useOdienceOrganization";
import OdiencePreviewMobileHeader from "../../components/OdiencePreviewMobileHeader";
import { getCategoryImage } from "../eventList/helpers/EventListUtils";
import {
  EventDetailsActionRow,
  EventDetailsBackButton,
  EventDetailsContainer,
  EventDetailsDescriptionContainer,
  EventDetailsMainContainerPosition,
  EventDetailsRoot,
  EventDetailsTitle,
  GetTheAppButton,
  SuperAdminAccessButton,
} from "./EventDetailsPage.style";
import AddToCalendar from "./components/AddToCalendar";
import EventDetailsMap from "./components/EventDetailsMap";
import GuestList from "./components/GuestList";
import JoinButton from "./components/JoinButton";
import PromoVideo from "./components/PromoVideo";
import ShareEvent from "./components/ShareEvent";
import { formatDuration } from "./helpers/EventDetailsUtils";

const extractAndStoreEventDetails = (pathname: string, search: string) => {
  const parts = pathname.split("/");

  if (parts.length >= 6 && (parts[2] === "event" || parts[3] === "event")) {
    const groupId = parts[4];
    const eventId = parts[5];
    setLastGroupId(groupId);
    setLastEventId(eventId);
  }

  // Fix any potential HTML-encoded ampersands before parsing
  const fixedSearch = search.replaceAll("&amp;", "&").replaceAll("%3B", "&");
  const queryParams = new URLSearchParams(fixedSearch);

  const email = queryParams.get("email");
  const inviteId = queryParams.get("inviteId");

  if (email) {
    setEmail(email);
  }

  if (inviteId) {
    setInvite(inviteId);
  }
};

type Props = {
  odienceOnly?: boolean;
};
const EventDetails = ({ odienceOnly = false }: Props) => {
  const [objEvent, setObjEvent] = useState<any>(null);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState("");
  const [eventOpened, setEventOpened] = useState(false);
  const socket = useRef<ReturnType<typeof io> | null>(null);
  const { eventId, groupId } = useParams();
  const location = useLocation();
  const currentPath = location.pathname;
  const previewOdiencePath = paths.previewOdience;
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [isAppModalOpen, setAppModalOpen] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { organizationId, embeddedMode } = useOdienceOrganization();
  const [showEventEnded, setShowEventEnded] = useState(false);
  const [staffPassModalOpen, setStaffPassModalOpen] = useState(false);
  const updateIframeUrl = useParentIframeUrl({
    initialUrl: window.location.href,
    embeddedMode,
  });
  const network = useNetworkState();

  const loadEventUI = (event) => {
    setObjEvent(event);
    setEventOpened(event.opened);
    setBackgroundImageUrl(event.imageUrl);
    if (!event.opened) {
      setTimeout(() => {
        setEventOpened(true);
        socket.current!.emit("EventOpened", { eventId: eventId });
      }, 3000);
    }
  };

  useEffect(() => {
    updateIframeUrl(window.location.href);
  }, []);

  const setShowPrivateEventPopup = useSetAtom(atoms.odience.privateEventPopup);

  useEffect(() => {
    extractAndStoreEventDetails(location.pathname, location.search);
  }, [location]);

  if (isProvisioned() && getEmail() && getInvite() && objEvent) {
    removeEmail();
    removeInvite();
    removeInvitedEventId();
    removeLastEventId();
    removeLastGroupId();
  }

  const getPublicEvent = async () => {
    if (eventId && currentPath.includes(previewOdiencePath)) {
      const event = await fetchPublicEventInfo(eventId);
      return event[0];
    }
  };

  const fetchEvent = async (odienceOnly: boolean) => {
    let eventInfo;
    if (eventId && groupId) {
      if (!odienceOnly && !currentPath.includes(previewOdiencePath)) {
        eventInfo = await fetchEventInfo(groupId, eventId, showToast);
      } else {
        eventInfo = await getPublicEvent();
      }

      if (!eventInfo || isEmptyObject(eventInfo)) {
        if (!isProvisioned()) {
          setShowPrivateEventPopup(true);
        }
        if (isEmptyObject(eventInfo)) {
          showToast(
            "The specified event is either private or not available to be viewed.",
            "bottom-right",
            "1vw"
          );
        }

        navigate(
          currentPath.includes(previewOdiencePath)
            ? previewOdiencePath
            : paths.odience
        );
        return;
      }

      if (eventInfo.label === "ended") {
        setShowEventEnded(true);
        removeLastEventId();
        removeLastGroupId();
      }

      loadEventUI(eventInfo);
    }
  };

  const fetchUser = async () => {
    const odienceUser = await authenticateDirector();

    if (odienceUser) {
      setIsSuperAdmin(odienceUser?.roles?.super_admin || false);
    }
  };

  const connectSocket = () => {
    if (!network.online) {
      return;
    }

    disconnectSocket();

    void fetchEvent(odienceOnly);
    if (!currentPath.includes(previewOdiencePath)) void fetchUser();

    // Router Events
    const url = routerUrl;
    const userId = (getLocalUser() ?? "").replaceAll("+", "");
    const option = currentPath.includes(previewOdiencePath)
      ? {
          transports: ["websocket"],
          query: {
            user_id: `guest-${generateRandomString(15)}`,
          },
        }
      : {
          transports: ["websocket"],
          query: { user_id: userId, token: getLocalDirectorToken() },
        };

    console.log("Connecting to socket");
    socket.current = io(url, option);

    socket.current.on("Connected", () => {
      if (!isSocketIOConnected(socket)) {
        return;
      }

      socket.current.emit("GetEventInfo", { eventId: eventId });
    });

    socket.current.on("EventUpdated", (event) => {
      if (event.id === eventId) {
        void fetchEvent(odienceOnly);
        setObjEvent(event);
      }
    });
  };

  const disconnectSocket = () => {
    console.log("Disconnecting from socket");
    socket.current?.removeAllListeners();
    socket.current?.disconnect();
    socket.current = null;
  };

  useEffect(() => {
    connectSocket();

    return () => {
      disconnectSocket();
    };
  }, [network.online]);

  useUnmount(() => {
    disconnectSocket();
  });

  const goBack = () => {
    setShowPrivateEventPopup(false);
    navigate(generateEventListPath(objEvent, embeddedMode));
  };

  const openStreamPage = () => {
    const streamPath = paths.stream.replace(":eventId", objEvent.id);
    setShowPrivateEventPopup(false);
    navigate(streamPath);
  };

  if (!objEvent) return;

  return (
    <>
      {isMobile() && <OdiencePreviewMobileHeader page={"eventDetails"} />}
      <EventDetailsRoot
        css={{
          background: `url(${backgroundImageUrl}), url("/odience/event/empty_event_image.jpg")`,
          height: embeddedMode || !isProvisioned() ? "100%" : "90vh",
          width: embeddedMode || !isProvisioned() ? "100%" : "90vw",
          left: embeddedMode || !isProvisioned() ? 0 : "10vw",
          borderRadius: embeddedMode || !isProvisioned() ? 0 : "16px",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {!isMobile() && (
          <>
            <EventDetailsBackButton
              onClick={goBack}
              css={{
                top: embeddedMode || !isProvisioned() ? "5vh" : "10vh",
                left: embeddedMode || !isProvisioned() ? "5vh" : "15vw",
              }}
            >
              <img
                src={BackIcon}
                alt="Back"
                css={{
                  height: "1.6em",
                  width: "1.6em",
                }}
              />
            </EventDetailsBackButton>

            {objEvent.web_allowed !== 0 && (
              <GetTheAppButton
                onClick={() => {
                  setAppModalOpen(true);
                }}
              >
                <img src={QrSmall} style={{ height: "1.5em" }} />
                Get the app for a <br /> better experience
              </GetTheAppButton>
            )}
          </>
        )}

        {isSuperAdmin && (
          <SuperAdminAccessButton onClick={() => setStaffPassModalOpen(true)}>
            <img src={StaffIcon} style={{ height: "1.6em" }} />
            <span>Staff Pass</span>
          </SuperAdminAccessButton>
        )}

        <div
          css={{
            position: "fixed",
            top: isMobile()
              ? "12.5vh"
              : embeddedMode || !isProvisioned()
                ? "7vh"
                : "12vh",
            right: !isProvisioned() && isMobile() ? "1vh" : "5vw",

            zIndex: 1000,
            display: "flex",
            flexDirection: "column",
            gap: "3vh",
            "@media (orientation: landscape)": {
              gap: "5vh",
              top: isMobile()
                ? "20vh"
                : embeddedMode || !isProvisioned()
                  ? "7vh"
                  : "12vh",
            },
          }}
        >
          {(!embeddedMode || !organizationId) && (
            <ShareEvent objEvent={objEvent} />
          )}
          {objEvent.label === "upcoming" && (
            <AddToCalendar objEvent={objEvent} />
          )}
        </div>

        <EventDetailsMainContainerPosition>
          <EventDetailsContainer>
            {objEvent.is_public && <GuestList objEvent={objEvent} />}
            <h1 css={EventDetailsTitle}>{objEvent.name}</h1>

            <div css={EventDetailsActionRow}>
              {objEvent.promo_video_url && <PromoVideo objEvent={objEvent} />}

              <JoinButton objEvent={objEvent} />
            </div>

            {objEvent.duration > 0 && (
              <div css={{ color: colors.secondaryTextColor }}>
                Duration: {formatDuration(objEvent.duration)}
              </div>
            )}

            <EventDetailsDescriptionContainer>
              <div
                css={{
                  display: "flex",
                  justifyContent:
                    objEvent.capacity > 0 ||
                    !objEvent.is_public ||
                    objEvent.description ||
                    objEvent.location
                      ? "space-between"
                      : "center",
                }}
              >
                <EventCategory event={objEvent} />
                <div
                  css={{
                    marginLeft: "0.5em",
                    display: "flex",
                    alignItems: "center",
                    gap: "2vw",
                    fontSize: isMobile() ? "0.6rem" : "1rem",
                  }}
                >
                  {objEvent.capacity > 0 && <div>CAP: {objEvent.capacity}</div>}
                  {!objEvent.is_public && (
                    <div
                      css={{
                        color: colors.secondaryAccentColor,
                        display: "flex",
                        alignItems: "center",
                        paddingRight: "0.7vw",
                        gap: "0.5vw",
                      }}
                    >
                      <img
                        src={PrivateIcon}
                        alt="Private Event"
                        css={{
                          height: "0.6em",
                        }}
                      />{" "}
                      PRIVATE
                    </div>
                  )}
                </div>
              </div>

              {objEvent.description && (
                <div
                  css={{
                    color: colors.secondaryTextColor,
                    fontSize: isMobile() ? "0.875rem" : "1rem",
                  }}
                >
                  {objEvent.description}
                </div>
              )}

              {objEvent.location && (
                <div>
                  <div
                    css={{
                      display: "flex",
                      gap: "0.5em",
                      alignItems: "center",
                      paddingBottom: "1vh",
                      fontSize: isMobile() ? "0.875rem" : "1rem",
                    }}
                  >
                    {" "}
                    <img
                      src="/odience/event/location.svg"
                      alt="Event Location"
                      css={{ height: "1em" }}
                    />
                    {objEvent.location}
                  </div>
                  <EventDetailsMap objEvent={objEvent} />
                </div>
              )}
            </EventDetailsDescriptionContainer>
          </EventDetailsContainer>
        </EventDetailsMainContainerPosition>
      </EventDetailsRoot>
      <GetAppModal
        show={isAppModalOpen}
        onCloseModal={() => setAppModalOpen(false)}
        objEvent={objEvent}
      />
      {showEventEnded && (
        <ConfirmationPopup
          title={"Event has ended"}
          confirmationMessage="Thank you for participating. Join us again next time or explore the Events List for more events."
          handleAction={() =>
            navigate(generateEventListPath(objEvent, embeddedMode))
          }
          primaryButtonText="Return to Event List"
          togglePopup={() =>
            navigate(generateEventListPath(objEvent, embeddedMode))
          }
          closeButtonActive={false}
          secondaryButtonActive={false}
        />
      )}
      {staffPassModalOpen && (
        <ConfirmationPopup
          title={"Staff Pass"}
          confirmationMessage={
            "You have been granted a staff pass for this event! You may join the event before it goes live."
          }
          togglePopup={() => setStaffPassModalOpen(false)}
          handleAction={() => {
            openStreamPage();
            setStaffPassModalOpen(false);
          }}
          primaryButtonText={"Join Now"}
        />
      )}
    </>
  );
};

export default EventDetails;

type EventCategoryProps = {
  event: {
    category: string;
    organization: string;
    organization_image_url: string;
  };
};

const EventCategory = ({ event }: EventCategoryProps) => {
  const categoryTitle = categories[event.category] as string;
  const categoryImage = getCategoryImage(event.category);
  const organization = event.organization;

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: "0.5vw",
        fontSize: isMobile() ? "0.6rem" : "1rem",
      }}
    >
      <img
        css={{ height: "1em" }}
        src={categoryImage}
        alt={`${categories[event.category]} tag`}
      />
      <span>
        {categoryTitle} • Hosted by {organization}
      </span>{" "}
      <img
        css={{
          borderRadius: "50%",
          height: "1.25em",
          width: "1.25em",
          objectFit: "cover",
        }}
        src={event.organization_image_url}
        onError={(e) => {
          e.currentTarget.src = OdienceLogoDefault;
        }}
        alt={`${event.organization} logo`}
      />
    </div>
  );
};
